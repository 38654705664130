import React, { useState } from 'react'
import { graphql } from "gatsby"

import Layout from '../components/layout'
import SEO from '../components/seo'

import gsap from 'gsap';


const ContactPage = () => {
  const [formStage, setFormStage] = useState(0)
  const [contact, setContact] = useState({
    name: "",
    email: "",
    message: ""
  })
  const [showButton, setShowButton] = useState(false)
  
  const contactText = ["Nice to meet you! Can you tell us your name?", "Can you provide us your email address?", "Please describe how can we help you:", "That's all we need! We will contact you in the next few hours."]
  const inputName = ["name", "email", "message"]
  const inputValue = [contact.name, contact.email, contact.message]

  let tl = gsap.timeline();

  const scrollUpAnimation = () => {
    tl.to('.contacts-section__form-container', {
      opacity: 0, 
      duration: 0.5,
      y: -150,
      onComplete: addStage 
    })
    .to('.contacts-section__form-container', {
      y: 150,
      duration: 0,
      opacity: 0
    })
    .to('.contacts-section__form-container', {
      y: 0,
      duration: 0.5,
      opacity: 1
    })
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setShowButton(true)
    if (!value) {
      setShowButton(false)
    }

    setContact(prevState => {
      if (name === "name"){
        return {
          name: value,
          email: prevState.email,
          message: prevState.message
        }
      } else if (name === "email") {
        return {
          name: prevState.name,
          email: value,
          message: prevState.message
        }
      } else if (name === "message") {
        return {
          name: prevState.name,
          email: prevState.email,
          message: value
        }
      }
    })
  }

  const handleSubmit = () => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ firstName: contact.name, email: contact.email, message: contact.message})
    };
    fetch('https://forcera.pt/contacts', requestOptions)
    .then(response => response.json());
    setFormStage(formStage + 1)
  }

  const handleNextBtn = (e) => {
    e.preventDefault()
    scrollUpAnimation()
  }

  function addStage(){
    setFormStage(formStage + 1)
    setShowButton(false)
  }


  return (
    <Layout>
      <SEO
        title="Forcera"
        keywords={[`software development`, `ai`, `big data`, `cloud engineering`, `machine learning`, `data strategy`]}
      />
      <div className="contacts-section container">
        <div className="contacts-section__form-container"> 
      <h3>{contactText[formStage]}</h3> 
      <form>
        {
          formStage <= 1 && 
          <>
            <input
              onChange={handleChange}
              name={inputName[formStage]}
              value={inputValue[formStage]}
              placeholder=""
              autoComplete="off"
            />
          </>
        }
        {
          formStage === 2 && 
          <textarea
          onChange={handleChange}
          type="textarea"
          name="message"
          value={contact.message}
          rows="5"
          autoComplete="off"
        />
          
        }
        { formStage < 2 ?
            <div>
            <button className={"btn " + (showButton ? "" : "btn-hide")} onClick={handleNextBtn} disabled={!showButton}>Next</button>
            </div>
          : null
        }

        {
          formStage === 2 ?
            <div>
            <button className={"btn " + (showButton ? "" : "btn-hide")} onClick={handleSubmit} disabled={!showButton}>Submit</button>
            </div>
          : null
        }
      </form>
      </div>
      </div>
    </Layout>
  )
}

export default ContactPage

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`   
